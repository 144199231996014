<template>
  <div id="import-files-page">

    <ImportFileUploadModal 
      ref="import-file-upload-modal"
      @on_import_file_created="on_import_file_created"
    />

    <b-button type="button" variant="primary" @click.prevent="create_import_file_clicked()"
      >{{ $t('PAGES.IMPORT.UPLOAD_FILE') }}</b-button
    >

    <ImportFilesTable
      class="mt-8"
      :files="file_categories"
      @on_upload_file_clicked="on_upload_file_clicked"
      
    />
    
  </div>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import ImportFilesTable from '@/view/pages/ml/faith/import/ImportFilesTable.vue';
import ImportFileUploadModal from '@/view/pages/ml/faith/import/ImportFileUploadModal.vue';

export default {
  name: 'import-files-page',
  mixins: [ toasts ],
  components: {
    ImportFilesTable,
    ImportFileUploadModal
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods']),
    
    selected_period_name() {
      const period = this.periods.find(item => item.id === this.currentPeriodId);

      return period ? period.name : '-';
    },

  },
  mounted() {
    this.load_files(this.currentPeriodId);
  },  
  
  data() {
    return {
      file_type_for_upload: null,
      file: null,
      file_id: null,
      file_categories: [
        {
          type: 'SKV_IMPORT_INCOME_PERSONNR_ADVANCE',
          period: {},
          created_at: '-',
          user: {},
          history: [],
          upload_file: ''
        },
        {
          type: 'SKV_IMPORT_INCOME_PERSONNR_SETTLED',
          period: {},
          created_at: '-',
          user: {},
          history: [],
          upload_file: ''
        },

      ],
      files: [
        
      ]
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
      this.load_files(this.currentPeriodId);
    }

  },
  methods: {
    on_import_file_created(import_file) {
      this.load_files(this.currentPeriodId);
    },

    on_upload_file_clicked(type) {
      this.$refs['import-file-upload-modal'].show();
      this.$refs['import-file-upload-modal'].set_type(type);
    },

    create_import_file_clicked() {
      this.$refs['import-file-upload-modal'].show();
    },
    
    async load_files(period_id) {
      try {
        const res = await axios.get(`/skv/files/${period_id}`);

        if (res.status !== 200) {
          this.toastr('danger', this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.UNABLE_LOAD_FILES')));
          return;
        }

        this.files = res.data;

        const period = this.periods.find(item => item.id === period_id);

        for (const category of this.file_categories) {
          category.period = period;
          category.user_name = category.user ? (category.user?.firstname + ' ' + category.user?.lastname) : '-';
          category.history = this.files.filter(item => item.type === category.type);
          category.history.sort((a, b) => {
            if (a.created_at < b.created_at) {
              return 1;
            }
            return -1;
          });
          category.created_at = category.history.length === 0 ? '-' : category.history[0].created_at;
        }

      }
      catch (err) {
        console.error('load_files error', err);
      }
    },

  }
};
</script>
