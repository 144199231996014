<template>
  <div id="page-faith">
    <h5 class="m-4">{{ $t('AUTH.MENU.FAITH') }}</h5>

    <b-card>
      <v-tabs background-color="white" color="accent-4" left>
        <v-tab @click.prevent="refresh_overview">{{ $t('PAGES.FAITH.SUMMARY') }}</v-tab>
        <v-tab>{{ $t('PAGES.FAITH.EXPORT') }}</v-tab>
        <v-tab>{{ $t('PAGES.FAITH.IMPORT') }}</v-tab>
        <v-tab>{{ $t('PAGES.FAITH.REGISTRATIONS') }}</v-tab>
        <v-tab>{{ $t('PAGES.FAITH.CORRECTIONS') }}</v-tab>
        <v-tab>{{ $t('PAGES.FAITH.SETTINGS') }}</v-tab>
        
        <v-tab-item>
          <!-- Overview tab -->

          <CompanyPeriodsOverviewPage 
            class="mt-8"
            ref="overview-page"
          />
        </v-tab-item>

        <v-tab-item>
          <!-- Export tab -->

          <ExportFilesPage 
            class="mt-8"
          />
        </v-tab-item>

        <v-tab-item>
          <!-- Import tab -->
          
          <ImportFilesPage 
            class="mt-8"
          />
        </v-tab-item>

        <v-tab-item>
          <!-- RegistrationPage tab -->
          
          <RegistrationPage
            class="mt-8"
          />
        </v-tab-item>

        <v-tab-item>
          <!-- Corrections tab -->
          
          <CorrectionsPage
            class="mt-8"
          />
        </v-tab-item>

        <v-tab-item>
          <!-- Settings tab -->

          <FaithSystemSettings 
            class="mt-8"
            v-if="sid === currentCompanyId" 
          />
          
        </v-tab-item>

      </v-tabs>

    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import CompanyPeriodsOverviewPage from '@/view/pages/ml/faith/company_period/CompanyPeriodsOverviewPage.vue';
import FaithSystemSettings from '@/view/pages/ml/faith/FaithSystemSettings.vue';
import ExportFilesPage from '@/view/pages/ml/faith/export/ExportFilesPage.vue';
import ImportFilesPage from '@/view/pages/ml/faith/import/ImportFilesPage.vue';
import RegistrationPage from '@/view/pages/ml/faith/member_jobs/RegistrationPage.vue';
import CorrectionsPage from '@/view/pages/ml/faith/member_jobs/CorrectionsPage.vue';

export default {
  name: 'faith-page',
  mixins: [ toasts ],
  components: {
    CompanyPeriodsOverviewPage,
    ExportFilesPage,
    ImportFilesPage,
    FaithSystemSettings,
    RegistrationPage,
    CorrectionsPage
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'sid'])
  },
  mounted() {
    
  },
  data() {
    return {

    };
  },
  watch: {
    
  },
  methods: {
    refresh_overview() {
      this.$nextTick(()=>{
        this.$refs['overview-page'].refresh();
      });
    }
  }
};
</script>
