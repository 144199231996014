<template>
  <div>

    <b-row>
      <b-col cols="6">
        <b-form-group
          :label="$t('COMPANY_PERIOD.UPDATED_AT')"
          id="group-updated_at">

          <b-form-input
            id="field-updated_at"
            type="text"
            disabled
            v-model="value.updated_at"
            trim>
          </b-form-input>

        </b-form-group>
      </b-col>
    </b-row>


    <b-row>
      <b-col>

        <b-form-group id="group-skv_advance_imported_at">
          <tip-label class="mb-2" for="field-skv_advance_imported_at">{{$t('COMPANY_PERIOD.SKV_ADVANCE_IMPORTED_AT')}}</tip-label>

          <b-form-input
            id="field-skv_advance_imported_at"
            type="text"
            disabled
            v-model="value.skv_advance_imported_at"
            trim>
          </b-form-input>

        </b-form-group>

        <b-form-group id="group-advance_calculated_at">
          <tip-label class="mb-2" for="field-advance_calculated_at">{{$t('COMPANY_PERIOD.ADVANCE_CALCULATED_AT')}}</tip-label>

          <b-form-input
            id="field-advance_calculated_at"
            type="text"
            disabled
            v-model="value.advance_calculated_at"
            trim>
          </b-form-input>

        </b-form-group>

        <!--
          <b-button variant="primary" @click.prevent="calculate_advance">
              {{$t('COMPANY_PERIOD.CALCULATE_ADVANCE')}}
            </b-button>
        -->

        <b-card class="mt-4" :header="$t('COMPANY_PERIOD.ADVANCE_INFO_HEADER', { period: value.advance_period.name })">

          <TextInfoRow
            v-for="(row, index) of advance_text_info_rows" :key="index"
            v-bind:value="row"
          />

          <b-row>
            <b-col>
              <b-form-group
                id="input-group-advance_payout_amount"
                label-for="input-advance_payout_amount"
              >
                <tip-label class="mb-2" for="field-advance_payout_amount" asterix>{{$t('PAGES.FAITH.AMOUNT_PAID')}}</tip-label>

                <b-form-input
                  id="input-advance_payout_amount"
                  v-model="value.advance_payout_amount"
                >
                </b-form-input>
                <b-form-text>{{$t('PAGES.FAITH.AMOUNT_PAID_TO_COMPANY_INFO', { period: value.advance_period.name, company: value.company.name })}}</b-form-text>

              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button variant="primary" @click.prevent="save_advance">
                {{$t('COMMON.SAVE')}}
              </b-button>
            </b-col>
          </b-row>


        </b-card>

      </b-col>
      <b-col>

        <b-form-group id="group-skv_settled_imported_at">
          <tip-label class="mb-2" for="field-skv_settled_imported_at">{{$t('COMPANY_PERIOD.SKV_SETTLED_IMPORTED_AT')}}</tip-label>

          <b-form-input
            id="field-skv_settled_imported_at"
            type="text"
            disabled
            v-model="value.skv_settled_imported_at"
            trim>
          </b-form-input>

        </b-form-group>

        <b-form-group id="group-settled_calculated_at">
          <tip-label class="mb-2" for="field-settled_calculated_at">{{$t('COMPANY_PERIOD.SETTLED_CALCULATED_AT')}}</tip-label>

          <b-form-input
            id="field-settled_calculated_at"
            type="text"
            disabled
            v-model="value.settled_calculated_at"
            trim>
          </b-form-input>

        </b-form-group>



        <!--<b-button variant="primary" @click.prevent="calculate_settled">
          {{$t('COMPANY_PERIOD.CALCULATE_SETTLED')}}
        </b-button>-->

        <b-card class="mt-4" :header="$t('COMPANY_PERIOD.SETTLED_INFO_HEADER', { period: value.settled_period.name })">

          <TextInfoRow
            v-for="(row, index) of settled_text_info_rows" :key="index"
            v-bind:value="row"
          />

        </b-card>

      </b-col>
    </b-row>


  </div>
</template>

<style lang="scss" scoped>

/* add some styles here */

.card-header {
  background: #efefef;
}

.card-body {
  border: 1px solid #efefef;
}

</style>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import TextInfoRow from '@/view/pages/ml/faith/company_period/TextInfoRow.vue';
import TipLabel from '@/view/components/TipLabel.vue';
import axios from 'axios';

export default {
  name: 'company-period-info-box',
  mixins: [ toasts ],
  components: {
    TextInfoRow,
    TipLabel
  },
  props: {
    value: { // this is selected company_period
      type: Object,
      required: true
    },

  },
  emits: ['company_period_updated', 'calculate_settled', 'calculate_advance'],
  watch: {

    // when values has changed in the form
    value: {
      deep: true, // check properties of the form, not just the form reference (value.name for example)
      handler(val) {
        // emit the value updated, will emit the form in its entirety
        // example: val = { example_id: 1, name: 'test', name2: 'test2' }
        //this.$emit('update:example', val);

      }
    },
  },
  computed: {


    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'isTHS']),

    advance_text_info_rows() {
      this.calculate_table();

      const rows = Object.values(this.advance_captions);

      return rows;
    },
    settled_text_info_rows() {
      this.calculate_table();

      const rows = Object.values(this.settled_captions);

      return rows;
    }
  },
  // when component is mounted/rendered for the first time
  mounted() {
    if (this.isTHS) {

    }
  },
  // local data to the component can be stored here
  data() {
    return {

      advance_captions: {
        num_members:
        {
          text: this.$t('COMPANY_PERIOD.A1_NUM_MEMBERS'),
        },
        sum_basis_fee:
        {
          text: this.$t('COMPANY_PERIOD.A2_SUM_BASIS_FEE'),
        },
        fee_per_member:
        {
          text: this.$t('COMPANY_PERIOD.A3_FEE_PER_MEMBER'),
        },
        advance_from_skv:
        {
          text: this.$t('COMPANY_PERIOD.A4_ADVANCE_FROM_SKV'),
        },
        adm_fee_per_member:
        {
          text: this.$t('COMPANY_PERIOD.A5_ADM_FEE_MEMBER'),
        },
        pct_fee_subcompany:
        {
          text: this.$t('COMPANY_PERIOD.A6_PCT_FEE_SUBCOMPANY'),
        },
        fee_to_topcompany:
        {
          text: this.$t('COMPANY_PERIOD.A7_FEE_TO_TOPCOMPANY'),
        },
        advance_payout:
        {
          text: this.$t('COMPANY_PERIOD.A8_ADVANCE_PAYOUT'),
        },
      },
      settled_captions: {
        num_members:
        {
          text: this.$t('COMPANY_PERIOD.B1_NUM_MEMBERS'),
        },
        sum_basis_fee:
        {
          text: this.$t('COMPANY_PERIOD.B2_SUM_BASIS_FEE'),
        },
        fee_per_member:
        {
          text: this.$t('COMPANY_PERIOD.B3_FEE_PER_MEMBER'),
        },
        settled_calculated_sum:
        {
          text: this.$t('COMPANY_PERIOD.B4_SETTLED_CALCULATED_SUM'),
        },
        adm_fee_per_member:
        {
          text: this.$t('COMPANY_PERIOD.B5_ADM_FEE_MEMBER'),
        },
        pct_fee_subcompany:
        {
          text: this.$t('COMPANY_PERIOD.B6_PCT_FEE_SUBCOMPANY'),
        },
        fee_to_topcompany:
        {
          text: this.$t('COMPANY_PERIOD.B7_FEE_TO_TOPCOMPANY'),
        },
        settled_sum:
        {
          text: this.$t('COMPANY_PERIOD.B8_SETTLED_SUM'),
        },
        difference:
        {
          text: this.$t('COMPANY_PERIOD.B9_DIFFERENCE'),
        },

      }
    };
  },

  methods: {

    async save_advance() {
      this.update_advance_payout_amount();
    },

    async update_advance_payout_amount() {
      try {
        const res = await axios.put(`/company_period/${this.value.id}`, {
          advance_payout_amount: this.value.advance_payout_amount
        });

        if (res.status === 204) {

          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('COMPANY_PERIOD.ADVANCE_PAYOUT_AMOUNT_UPDATED'));

          this.$emit('company_period_updated', this.value);

          return true;
        }
      }
      catch (err) {
        console.error('update_advance_payout_amount', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY_PERIOD.UNABLE_ADVANCE_PAYOUT_AMOUNT_UPDATE'));

      return false;
    },

    async calculate_settled() {
      // update the advance_payout_amount for 2021
      await this.update_advance_payout_amount();

      this.$emit('calculate_settled', this.value.id);
    },
    calculate_advance() {
      this.$emit('calculate_advance', this.value.id);
    },

    if_null_do(value, replacement) {
      if (value === null || value === undefined) {
        return replacement;
      }
      return value;
    },

    calculate_table() {
      if (this.value.advance_data === null || this.value.advance_data === undefined) {
        for (const cap in this.advance_captions) {
          this.advance_captions[cap].value = '-';
        }
      }
      else {
        this.advance_captions.num_members.value = this.if_null_do(this.value.advance_data.A1_NUM_MEMBERS, '-');
        this.advance_captions.sum_basis_fee.value = this.if_null_do(this.value.advance_data.A2_SUM_BASIS_FEE, '-');
        this.advance_captions.fee_per_member.value = this.if_null_do(this.value.advance_data.A3_FEE_PER_MEMBER, '-');
        this.advance_captions.advance_from_skv.value = this.if_null_do(this.value.advance_data.A4_ADVANCE_FROM_SKV, '-');
        this.advance_captions.adm_fee_per_member.value = this.if_null_do(this.value.advance_data.A5_ADM_FEE_MEMBER, '-');
        this.advance_captions.pct_fee_subcompany.value = this.if_null_do(this.value.advance_data.A6_PCT_FEE_SUBCOMPANY, '-');
        this.advance_captions.fee_to_topcompany.value = this.if_null_do(this.value.advance_data.A7_FEE_TO_TOPCOMPANY, '-');
        this.advance_captions.advance_payout.value = this.if_null_do(this.value.advance_data.A8_ADVANCE_PAYOUT, '-');
      }


      if (this.value.settled_data === null || this.value.settled_data === undefined) {
        for (const cap in this.settled_captions) {
          this.settled_captions[cap].value = '-';
        }
      }
      else {
        this.settled_captions.num_members.value = this.if_null_do(this.value.settled_data.B1_NUM_MEMBERS, '-');
        this.settled_captions.sum_basis_fee.value = this.if_null_do(this.value.settled_data.B2_SUM_BASIS_FEE, '-');
        this.settled_captions.fee_per_member.value = this.if_null_do(this.value.settled_data.B3_FEE_PER_MEMBER, '-');
        this.settled_captions.settled_calculated_sum.value = this.if_null_do(this.value.settled_data.B4_SETTLED_CALCULATED_SUM, '-');
        this.settled_captions.adm_fee_per_member.value = this.if_null_do(this.value.settled_data.B5_ADM_FEE_MEMBER, '-');
        this.settled_captions.pct_fee_subcompany.value = this.if_null_do(this.value.settled_data.B6_PCT_FEE_SUBCOMPANY, '-');
        this.settled_captions.fee_to_topcompany.value = this.if_null_do(this.value.settled_data.B7_FEE_TO_TOPCOMPANY, '-');
        this.settled_captions.settled_sum.value = this.if_null_do(this.value.settled_data.B8_SETTLED_SUM, '-');
        this.settled_captions.difference.value = this.if_null_do(this.value.settled_data.B9_DIFFERENCE, '-');
      }

    }
  }
};
</script>
