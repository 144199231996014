<template>
  <div>
    <b-modal ref="popup" hide-footer >
      <b-progress :max="max_value" height="2rem">
        <b-progress-bar :value="current_value">
          <span>Progress: <strong>{{ current_value.toFixed(2) }} / {{ max_value }}</strong></span>
        </b-progress-bar>
      </b-progress>
    </b-modal>
    
  </div>
</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'GenericProgressPopup',
  components: {
    Confirm
  },
  mixins: [ toasts ],
  props: ['max_value','current_value'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'companies']),
  },

  beforeDestroy() {
    
  },

  mounted() {
    
  },
  data() {
    return {
      
    };
  },
  watch: {
    

  },
  methods: {
    show() {
      this.$refs['popup'].show();
    }
  }
};
</script>
