<template>
  <b-modal size="lg" ref="company-edit-modal" hide-footer>
    <p style="font-size: 34px; color: red; font-weight: bold;">EJ KLAR - EJ KLAR - EJ KLAR</p>
    <BasicCompanySettings 
      :company="company"
      :regions="regions"
      :extras="extras"
    />
  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';


// todo: We need a more scalable solution for passing data and updating data to Basic.vue
import BasicCompanySettings from '@/view/pages/ml/settings/BasicCompanySettings.vue';

export default {
  name: 'company-edit-modal',
  mixins: [ toasts ],
  components: {
    BasicCompanySettings
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods'])
  },
  mounted() {

  },
  data() {
    return {
      // Basic.vue should use the bind/update pattern
      company: {},
      regions: {},
      extras: [],
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    // add any methods we need
    show() {
      this.$refs['company-edit-modal'].show();
    }
  }
};
</script>
