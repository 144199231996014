<template>
  <div>

    <b-table
      id="export-files-table"
      class="table-striped mh-100 table-head-custom table-head-bg"
      :fields="headers"
      :items="files"
      head-variant="light"
      sticky-header
      responsive
      striped
      selectable
      hover
    >
    <template #cell(actions)="row">
      <div class='justify-content-end d-flex'>

        <a
          href="#"
          class="btn btn-icon btn-light btn-sm mx-3"
          @click.prevent="download_file_clicked(row.item.file_id)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">

            <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>

          </span>
        </a>

      </div>
    </template>

    </b-table>

    <div v-if="files.length === 0">
      {{ $t('COMPANY_PERIOD.NO_ROWS_FOUND') }}
    </div>

  </div>

</template>
<style lang="css" scoped>
.pagination-select {
  height: 32px;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 12px;
  max-width: 100px;
}

:deep .b-table-details td, tr {
  padding: 0px;
}

:deep .w-type {
  width: 290px;
}

:deep .w-period {
  width: 120px;
}

:deep .w-created {
  width: 200px;
}

:deep .w-username {
  width: 180px;
}

:deep .w-generated {
  width: 240px;
}

:deep .w-history {
  width: 80px;
}

</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import 'vue-select/dist/vue-select.css';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: "member-jobs-file-table",
  mixins: [ toasts ],
  props: ["files","show_create"],
  emits: ['create_company_period','select_company','select_company_period','delete_company_period'],
  components: {

  },
  watch: {

  },
  computed:{
    ...mapGetters(['isTHS','currentCompanyId','currentPeriodId']),
  },
  mounted() {
    if (this.isTHS) {
      // do something as THS admin?
    }
  },
  methods: {

    is_empty(name) {
      if (!name || name.length === 0 || name === ' ') {
        return true;
      }
      return false;
    },

    download_file(file_id) {
      axios
        .get(`/file/${file_id}`)
        .then(res => {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_DOWNLOAD'));
        });
    },

    download_file_clicked(file_id) {
      this.download_file(file_id);
    },

    handle_row_clicked(row, index, e) {
      if (row._showDetails) {
        row._showDetails = false;
      }
      else {
        row._showDetails = true;
      }
      // Vue3 compatability for Vue.set
      row = { ...row };
    },

    add_new_row(item) {
      item.newRow = this.newRowValue;
      // Vue3 compatability for Vue.set
      item = { ...item };

      this.newRowValue = "";
    },

    replace_null_empty(value) {
      if (value === null || value === undefined) {
        return '';
      }

      return value;
    },

    create_company_period_clicked() {
      this.$emit('create_company_period', company_period_id);
    },

    select_company_clicked(company_id) {
      this.$emit('select_company', company_id);
    },

    select_company_period_clicked(company_period_id) {
      this.$emit('select_company_period', company_period_id);
    },

    delete_company_period_clicked(company_period_id) {
      this.$emit('delete_company_period', company_period_id);
    }

  },
  data() {
    return {
      newRowValue: "",
      filters: {
        text: ''
      },
      total_rows: 0,
      per_page: 100,
      current_page: 1,
      per_page_options: [
        100, 250, 500
      ],
      headers: [
        {
          formatter: (_, __, item) => {
            return this.$t('PAGES.FAITH.' + item.type);
          },
          key: 'type',
          label: this.$t('PAGES.FAITH.FILE_TYPE'),
          sortable: false,
          thClass: 'pl-7 align-middle w-type',
          tdClass: 'pl-7 align-middle w-type'
        },
        {
          key: 'period_id',
          label: this.$t('PAGES.FAITH.FOR_PERIOD'),
          sortable: false,
          thClass: 'pl-7 align-middle w-period',
          tdClass: 'pl-7 align-middle w-period',
          formatter: (_, __, item) => {
            return item.period.year;
          },
        },

        {
          key: 'created_at',
          label: this.$t('PAGES.FAITH.GENERATED_AT'),
          sortable: false,
          thClass: 'pl-7 align-middle w-created',
          tdClass: 'pl-7 align-middle w-created'
        },
        {
          key: 'user',
          label: this.$t('PAGES.FAITH.USER_NAME'),
          sortable: false,
          thClass: 'pl-7 align-middle w-username',
          tdClass: 'pl-7 align-middle w-username',
          formatter: (_, __, item) => {
            if (this.is_empty(item.user.firstname) || this.is_empty(item.user.lastname)) {
              return this.$t('PAGES.FAITH.NAME_MISSING');
            }

            const user_name = item.user.firstname + ' ' + item.user.lastname;

            return user_name;
          },
        },

        {
          key: 'actions',
          label: "",
          sortable: false
        },

        {
          key: 'expand',
          label: "",
          sortable: false
        },
      ],

    };
  }
};
</script>
