<template>
  <b-modal size="lg" ref="company-period-edit-modal" hide-footer>
    <p style="font-size: 34px; color: red; font-weight: bold;">EJ KLAR - EJ KLAR - EJ KLAR</p>

    <CompanyPeriodEdit 
      v-bind:value="value" v-on:update:company_period="updated_company_period"
    />
  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import CompanyPeriodEdit from '@/view/pages/ml/faith/company_period/CompanyPeriodEdit.vue';

export default {
  name: 'company-period-edit-modal',
  mixins: [ toasts ],
  components: {
    CompanyPeriodEdit
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['update:company_period'],
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods'])
  },
  mounted() {

  },
  data() {
    return {
      // Basic.vue should use the bind/update pattern
      company: {},
      regions: {},
      extras: [],
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    updated_company_period(company_period) {
      this.$emit('update:company_period', company_period);
    },

    show() {
      this.$refs['company-period-edit-modal'].show();
    }
  }
};
</script>
