<template>
  <div>
    <b-row>
      <b-col cols="9" class="text-sm-right">
        <p>{{ value.text }}</p>
      </b-col>
      <b-col cols="3">
        <p>{{ value.value }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

export default {
  name: 'text-info-row',
  mixins: [ toasts ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods'])
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  watch: {

    // when values has changed in the form
    value: {
      deep: true, // check properties of the form, not just the form reference (value.name for example)
      handler(val) {
        // emit the value updated, will emit the form in its entirety
        // example: val = { example_id: 1, name: 'test', name2: 'test2' }
        //this.$emit('update:example', val);
        console.log('data changed', this.value)
      }
    },

    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    // add any methods we need
    show() {
      this.$refs['company-edit-modal'].show();
    }
  }
};
</script>
