<template>
  <div>
    <b-card
      title=""
      class="mb-2 company-period-edit"
      ref="company-period-edit"
      id="company-period-edit"
      hide-footer
    >

      <b-form class="mt-8" @submit.prevent="on_submit">
        <b-form-group class="col-12" id="input-group-id" label="ID" label-for="input-id">
          <b-form-input
            id="input-id"
            v-model="local_value.id"
            type="text"
            required
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          class="col-lg-6 col-sm-12"
          id="input-group-mem_pct"
          :label="$t('PAGES.SETTINGS.MEM_PCT')"
          label-for="input-mem_pct">
          <b-form-input
            id="input-mem_pct"
            v-model="local_value.mem_pct"
            type="text"
            required
            :placeholder="$t('PAGES.SETTINGS.MEM_PCT')"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          class="col-lg-6 col-sm-12"
          id="input-group-fee_pct"
          :label="$t('PAGES.SETTINGS.FEE_PCT')"
          label-for="input-fee_pct">
          <b-form-input
            id="input-fee_pct"
            v-model="local_value.fee_pct"
            type="number" step="0.001"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          class="col-lg-6 col-sm-12"
          id="input-group-adm_fee"
          :label="$t('PAGES.SETTINGS.ADM_FEE')"
          label-for="input-adm_fee"
        >
          <b-form-input
            id="input-adm_fee"
            v-model="local_value.adm_fee"
            type="number" step="0.001"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          class="col-lg-6 col-sm-12"
          id="input-group-mem_fee"
          :label="$t('PAGES.SETTINGS.MEM_FEE')"
          label-for="input-mem_fee"
        >
          <b-form-input
            id="input-mem_fee"
            v-model="local_value.mem_fee"
            type="number" step="0.001"
          ></b-form-input>
        </b-form-group>

        <div class="mt-4">
          <b-button type="submit" :disabled="!is_valid" variant="primary">{{$t('COMMON.SAVE')}}</b-button>
        </div>

      </b-form>

    </b-card>
  </div>
</template>

<style lang="scss" scoped>

/* add some styles here */

</style>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// use axios for GET, PUT, POST, DELETE to API
//import axios from 'axios';
import { mapGetters } from 'vuex';

// date formatter
// import dayjs from 'dayjs';

export default {
  name: 'company-period-edit',
  mixins: [ toasts ],
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['update:company_period'],
  computed: {

    // check that the form is valid
    is_valid() {
      return true;
    },

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'isTHS']),

    // calculated, changed when value.id is updated
    creating() {
      return isNaN(parseInt(this.value.id+''));
    }
  },
  // when component is mounted/rendered for the first time
  mounted() {
    if (this.value) {
      this.local_value = { ...this.value }
    }
  },
  // local data to the component can be stored here
  data() {
    return {
      local_value: {},
    };
  },

  watch: {
    // when values has changed in the form
    local_value: {
      deep: true, // check properties of the form, not just the form reference (value.name for example)
      handler(val) {
        // emit the value updated, will emit the form in its entirety
        // example: val = { example_id: 1, name: 'test', name2: 'test2' }
        this.$emit('update:company_period', val);
      }
    },

  },
  methods: {
    // add any methods we need

    on_submit() {
      // submit was clicked
      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EXAMPLES.ON_SUBMIT'));
    }
  }
};
</script>
