<template>
  <div>
    <b-card v-if="value.history.length === 0">
      <strong>{{ $t('PAGES.FAITH.NO_FILES') }}</strong>
    </b-card>
    <div class="pt-4" v-else>
      <!-- List files -->
      <b-table
        id="files-table"

        :fields="headers"
        :items="value.history"
        responsive
        striped
        thead-class="minimized"
        @row-clicked="handle_row_clicked"
      >


        <template #cell(expand)="row">
          <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mr-8">
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mx-3 text-right"
              @click.prevent="download_file_clicked(row.item.file_id, row.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
              </span>
            </a>
          </div>

        </template>

      </b-table>
    </div>


  </div>
</template>

<style lang="scss" scoped>

/* add some styles here */
:deep .w-type {
  width: 290px;
}

:deep .w-period {
  width: 120px;
}

:deep thead {
  display: none;
}

:deep .w-created {
  width: 200px;
}

:deep .w-username {
  width: 180px;
}

:deep .w-generated {
  width: 240px;
}

:deep .w-history {
  width: 80px;
}

:deep td {
  height: 38px;
}

:deep tr:hover {
  background-color: white !important;
}

</style>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import TextInfoRow from '@/view/pages/ml/faith/company_period/TextInfoRow.vue';

import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: 'import-files-row-details',
  mixins: [ toasts ],
  components: {
    TextInfoRow
  },
  props: {
    value: { // this is selected company_period
      type: Object,
      required: true
    }
  },
  watch: {

    // when values has changed in the form
    value: {
      deep: true, // check properties of the form, not just the form reference (value.name for example)
      handler(val) {
        // emit the value updated, will emit the form in its entirety
        // example: val = { example_id: 1, name: 'test', name2: 'test2' }
        //this.$emit('update:example', val);

      }
    },
  },
  computed: {

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'isTHS']),

  },
  // when component is mounted/rendered for the first time
  mounted() {
    if (this.isTHS) {

    }
  },
  // local data to the component can be stored here
  data() {
    return {
      headers: [
        {
          formatter: (_, __, item) => {
            return this.$t('PAGES.FAITH.' + item.type);
          },
          key: 'type',
          label: this.$t('PAGES.FAITH.FILE_TYPE'),
          sortable: false,
          thClass: 'pl-7 align-middle w-type',
          tdClass: 'pl-7 align-middle w-type'
        },
        {
          key: 'period_id',
          label: this.$t('PAGES.FAITH.FOR_PERIOD'),
          sortable: false,
          thClass: 'pl-7 align-middle w-period',
          tdClass: 'pl-7 align-middle w-period',
          formatter: (_, __, item) => {
            return item.period.year;
          },
        },

        {
          key: 'created_at',
          label: this.$t('PAGES.FAITH.GENERATED_AT'),
          sortable: false,
          thClass: 'pl-7 align-middle w-created',
          tdClass: 'pl-7 align-middle w-created'
        },
        {
          key: 'user',
          label: this.$t('PAGES.FAITH.USER_NAME'),
          sortable: false,
          thClass: 'pl-7 align-middle w-username',
          tdClass: 'pl-7 align-middle w-username',
          formatter: (_, __, item) => {

            if (this.is_empty(item.user.firstname) || this.is_empty(item.user.lastname)) {
              return this.$t('PAGES.FAITH.NAME_MISSING');
            }

            const user_name = item.user.firstname + ' ' + item.user.lastname;

            return user_name;
          },
        },

        {
          key: 'history',
          label: this.$t('PAGES.FAITH.HISTORY'),
          sortable: false,
          thClass: 'pl-7 align-left w-history',
          tdClass: 'pl-7 align-left w-history',
          formatter: (_, __, item) => {
            return '';
          },
        },

        {
          key: 'upload_file',
          label: this.$t('PAGES.FAITH.UPLOAD_FILE'),
          sortable: false,
          thClass: 'pl-7 align-middle w-generated',
          tdClass: 'pl-7 align-middle w-generated'
        },
        {
          key: 'expand',
          label: "",
          sortable: false
        },
      ],
    };
  },
  methods: {
    is_empty(name) {
      if (!name || name.length === 0 || name === ' ') {
        return true;
      }
      return false;
    },
    handle_row_clicked(row, index, e) {},
    download_file_clicked(file_id, file) {
      downloadWithAxios(get_base_url() + `/dlfile/${file.linkstr}`, file.name);
    }
  }
};
</script>
